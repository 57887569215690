<template>
    <div class="mine-password-page">
        <GeekQooSearch></GeekQooSearch>
        <div class="top-title">
            <p>修改密码</p>
        </div>
        <div class="form-container">
            <div class="item-input">
                <van-icon name="lock"/>
                <input v-model="passwordForm.old" placeholder="请输入新密码" type="password">
            </div>
            <div class="item-input">
                <van-icon name="lock"/>
                <input v-model="passwordForm.new" placeholder="请确认新密码" type="password">
            </div>
            <van-button block class="item-button" color="#F28246" type="primary" @click="changePassword()">确认修改密码
            </van-button>
        </div>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入组件
import GeekQooSearch from "@/components/GeekQooSearch"
import {RESET_PASSWORD} from "@/api/dataProvider";

export default {
    name: "MinePassword",
    mixins: [mixins_config],
    components: {GeekQooSearch},
    data() {
        return {
            passwordForm: {
                old: "",
                new: ""
            }
        };
    },
    created() {
    },
    mounted() {
    },
    methods: {
        changePassword() {
            let that = this
            RESET_PASSWORD(
                {
                    password: that.passwordForm.old,
                    password_confirmation: that.passwordForm.new,
                },
                function (res) {
                    console.log(res)
                    if (res.data.code == 0) {
                        that.$notify({type: "success", message: res.data.message});
                        that.$router.go(-1)
                    } else {
                        that.$notify({type: "danger", message: res.data.message});
                    }
                }
            )
        }
    }
}
</script>

<style lang="scss">
.mine-password-page {
    padding: 0 0 50px 0;

    .top-title {
        background: #F96719;

        p {
            line-height: 35px;
            text-align: center;
            font-size: 10px;
            color: #fff;
        }
    }

    .form-container {
        padding: 180px 0 0 0;

        .item-input {
            width: 306px;
            margin: 14px auto 0 auto;
            border: 1px solid #EEEEEE;
            border-radius: 2px;

            i {
                vertical-align: middle;
                padding: 0 7px;
                font-size: 16px;
                color: #999999
            }

            input {
                vertical-align: middle;
                width: 274px;
                line-height: 30px;
                font-size: 10px;
                color: #333;
                border: none;
            }
        }

        .item-button {
            width: 306px;
            margin: 14px auto 0 auto;
        }
    }
}
</style>